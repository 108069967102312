import { Deseriazable } from './deserializable.model';

export class ResetPasswordModel implements Deseriazable{
    
    username: string;
    newPassword: string;
    againPassword: string;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}
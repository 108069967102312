import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordFormBuilder{

    private verifyForm: FormGroup;
    private resetPasswordForm: FormGroup;
    private validationRules = {
        email: ['', [Validators.required, Validators.email]],
        code: ['', [Validators.required]] 
    }
    private resetValidationRules = {
        email: ['', [Validators.required, Validators.email]],
        newPassword: ['', [Validators.required]],
        againPassword: ['', [Validators.required]], 
    }
    constructor(private fb:FormBuilder){
        this.verifyForm = fb.group(this.validationRules);
        this.resetPasswordForm = fb.group(this.resetValidationRules);
    }
    getForm(){
        return this.verifyForm;
    }
    getResetForm(){
        return this.resetPasswordForm;
    }
}
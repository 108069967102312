import { Component, OnInit } from '@angular/core';
import { PlaceholdersService } from '../modules/cms/services/placeholders.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DiscussionService } from '../modules/planners/services/discussions.service';
import { Config } from '../config';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.css']
})
export class ForumComponent implements OnInit {

  forums_discussions: any[] = [];
  discussionCategories: any[] = [];
  placeholderData: any;
  category_banner_image_url: string = Config.imageUrl + 'images/discussion_category_banner/';

  constructor(
    private discussionService: DiscussionService,
    private sanitizer: DomSanitizer,
    private placeholdersService:PlaceholdersService
  ) {}

  async ngOnInit() {
    let discussions = await this.discussionService.getPublicLatestDiscussion().toPromise();
    this.forums_discussions = discussions.data;
    this.forums_discussions = this.forums_discussions.filter((cat: any, index: number)=>{ return index < 5 });
    this.forums_discussions.forEach(discussion => {
      discussion.displayTitle = this.truncateTitle(discussion.discussion_title);
      discussion.isTitleHide = this.isTitleHide(discussion.discussion_title);
      discussion.limitedContent = this.getLimitedContent(discussion.discussion_details, discussion.slug);
    });
    console.log("Forum Discussion: ", this.forums_discussions);
    this.getDiscussionCategories();
  }
  async getDiscussionCategories() {
    let categories = await this.discussionService.getDiscussionCategories().toPromise();
    // categories = categories.filter((cat: any, index: number)=>{ return index < 6 });
    this.discussionCategories = categories;
  }
  getRichTextContent(content: any) {
    let el = document.createElement("div");
    el.innerHTML = content;
    let tempcontent = el.outerHTML;
    return this.sanitizer.bypassSecurityTrustHtml(tempcontent);
  }

  truncateTitle(title: string): string {
    const desktopLimit = 120;
    const mobileLimit = 90;
    const isMobile = window.innerWidth <= 768;
    const maxLength = isMobile ? mobileLimit : desktopLimit;
    if (title.length > maxLength) {
      return title.substring(0, maxLength);
    }
    return title;
  }

  isTitleHide(title: string){
    const desktopLimit = 120;
    const mobileLimit = 90;
    const isMobile = window.innerWidth <= 768;
    const maxLength = isMobile ? mobileLimit : desktopLimit;
    if (title.length > maxLength) {
      return true;
    }
    return false;
  }

  toggleFullTitle(event: MouseEvent, discussion: any) {
    event.stopPropagation();  // Stop the click event from propagating
    event.preventDefault();
    discussion.displayTitle = discussion.displayTitle === discussion.discussion_title
      ? this.truncateTitle(discussion.discussion_title) : discussion.discussion_title;
    discussion.isTitleHide = discussion.displayTitle === discussion.discussion_title? false : true;
  }

  getLimitedContent(content: string, slug: string) {
    const desktopLimit = 300;
    const mobileLimit = 270;
    const isMobile = window.innerWidth <= 768;
    const maxLength = isMobile ? mobileLimit : desktopLimit;
    if (content.length > maxLength) {
      content = content.substring(0, maxLength) + "... <a href='/conversation/" + slug + "'>See More</a>";
    }
    return this.getRichTextContent(content);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { LoginModel } from 'src/app/models/login.model';
import { ForgotPasswordModel } from 'src/app/models/forgotpassword.model';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { Config } from '../../../config';
import { RegisterModel } from 'src/app/models/register.model';
import { LocationModel } from 'src/app/models/location.model';
import { CategoryModel } from 'src/app/models/category.model';
import { ChangePasswordModel } from 'src/app/models/change-password.model';
import { BaseService } from '../../../services/base.service';
import { ResetPasswordModel } from 'src/app/models/resetpassword.model';
import { VerifyPasswordModel } from 'src/app/models/verifypassword.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UsersService {


  constructor(private http: HttpClient) { }

  getCurrentUserToken(): string{
    return localStorage.getItem("USER_TOKEN");
  }

  getCurrentUserFname(): string{
    return localStorage.getItem("fname");
  }

  private setCurrentUserToken(token:string){
    localStorage.setItem("USER_TOKEN",token);
  }

  private setCurrentUserId(id:string){
    localStorage.setItem("UID",id);
  }

  private setCurrentUserName(fname:string){
    localStorage.setItem("fname",fname);
  }

  private setCurrentUserRoles(){
    //localStorage.setItem("role",fname);
  }

  isAuthenticated(){
      if(this.getCurrentUserToken()) {
        return true;
      }
      return false;
  }

  getCurrentUserRole(): string{
      return localStorage.getItem("role");
  }

  authenticate(data: LoginModel): Observable<AuthResponseModel> {    
    return this.http
      .post(Config.serviceEndPoints.users.authenticate, data, httpOptions)
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }
        //console.log(response); 
        this.setCurrentUserToken(response.data.token); 
        this.setCurrentUserId(response.data.id); 
        this.setCurrentUserName(response.data.fname);
        localStorage.setItem("role",response.data.claims.roles[0].name);
        localStorage.setItem("parent_id",response.data.parent_id);
        return new AuthResponseModel().deserialize(response.data);
      }));       
  }

  forgetPassword(data: ForgotPasswordModel): Observable<ForgotPasswordModel> {
    return this.http
      .post(Config.serviceEndPoints.users.forgetpassword, data, httpOptions)
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return new ForgotPasswordModel().deserialize(response.data);         
      }));       
  }

  verifyPassword(data: VerifyPasswordModel): Observable<any> {
    return this.http
      .post(Config.serviceEndPoints.users.verifypassword, data, httpOptions)
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response.data;         
      }));       
  }

  resetPassword(data: ResetPasswordModel): Observable<ResetPasswordModel> {
    return this.http
      .post(Config.serviceEndPoints.users.resetpassword, data, httpOptions)
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return new ResetPasswordModel().deserialize(response.data);         
      }));       
  }

  create(data: RegisterModel): Observable<RegisterModel> {
    return this.http
      .post(Config.serviceEndPoints.users.register, data, httpOptions)
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return new RegisterModel().deserialize(response.data);         
      }));       
  }

  /*changePassword(data: ChangePasswordModel): Observable<ChangePasswordModel> {
      return this.http
        .post(Config.serviceEndPoints.vendors.changepassword, data, this.getHttpHeaders())
        .pipe(map((response:any) => {        
          if(response.hasError){
            throw new Error(response.message);
          }    
          return response;         
        }));       
    }
  getCities(){
    return this.http.get(Config.apiBaseUrl + "/cities", httpOptions);
  } */

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }
}

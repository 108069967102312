import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-community-login-popup',
  templateUrl: './community-login-popup.component.html',
  styleUrls: ['./community-login-popup.component.scss']
})
export class CommunityLoginPopupComponent implements OnInit {
  @Input() via = '';
  @Output() close= new EventEmitter();
  constructor(
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
  }

  openCommunityGuestLoginModal(){
    $('#community_guest_login_modal').modal('toggle');
  }
  goToUserLogin(){
    this.close.emit();
    this.router.navigate(["login"], {queryParams: {returnUrl: location.pathname}});
  }
  goToVendorLogin(){
    this.close.emit();
    this.router.navigate(["vendor", "login"], {queryParams: {returnUrl: location.pathname}});
  }
  goToUserSignup(){
    this.close.emit();
    this.router.navigate(["register"], {queryParams: {returnUrl: location.pathname}});
  }
  goToVendorSignup(){
    this.close.emit();
    this.router.navigate(["vendor", "register"], {queryParams: {returnUrl: location.pathname}});
  }
  forgotPassword(){
    this.router.navigate(["forgot-password"], {queryParams: {returnUrl: location.pathname}});
  }
}

import { Deseriazable } from './deserializable.model';

export class VerifyPasswordModel implements Deseriazable{
    
    username: string;
    code: string;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}